<template>
  <div class="promo-code">
    <!-- code text field -->
    <template v-if="!codeApplied">
      <v-text-field
        light
        :disabled="isLoading || checkoutProcessing"
        color="#000000"
        class="code-field"
        v-model.trim="checkoutPromoCode.value"
        :label="$t('cart_promo_code_promocode')"
        :error-messages="errors"
        data-testid="promo-code"
      />
      <mem-button
        @click="applyCode"
        :loading="isLoading"
        :disabled="checkoutProcessing"
        >{{ $t("add") }}</mem-button
      >
    </template>
    <!-- code applied -->
    <template v-else>
      <div class="code-applied">
        <span class="material-icons"> check_circle </span>
        <div>
          {{ `"${checkoutPromoCode.value}" ` + $t("cart_promo_code_applied") }}
        </div>
      </div>
      <div class="code-remove-btn" @click="deleteCode">
        <span class="material-icons"> cancel </span>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({}),
  props: {
    checkoutProcessing: Boolean,
    isLoading: Boolean,
    errors: Array,
  },
  computed: {
    ...mapGetters(["checkoutPromoCode"]),
    codeApplied() {
      if (this.isLoading) return false;

      let { isValid, value } = this.checkoutPromoCode;
      return isValid && value;
    },
  },
  methods: {
    ...mapActions(["validatePromoCode", "getOrderDescribe"]),
    ...mapMutations(["deletePromoCode"]),
    async applyCode() {
      if (this.checkoutProcessing) return;
      this.$emit("apply-code");
    },
    deleteCode() {
      if (this.checkoutProcessing) return;
      this.$emit("delete-code");
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
$text-field-heigt: 68px;
.promo-code {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  //   display: grid;
  //   grid-template-columns: max-content 1fr;
  button {
    margin-top: 6px;
    @media screen and (max-width: $mobile-view) {
      margin-top: 12px;
    }
  }
  .code-field {
    max-width: 206px;
    // font-weight: 700;
    ::v-deep(.v-label) {
      text-transform: capitalize;
      font-weight: 500;
      color: #4f4f4f;
    }
    ::v-deep(.v-text-field__slot) {
      font-weight: 700;
    }
  }
  .code-applied {
    height: $text-field-heigt;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #84bd00;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
    .material-icons {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .code-remove-btn {
    margin-top: 22px;
    display: flex;
    padding: 2px;
    @include cursorPointer;
    .material-icons {
      font-size: 20px;
      color: #bbbbbb;
    }
  }
}
</style>